import React from 'react'
import styled from 'styled-components'
import FooterIndex from '../components/footerIndex'

import Layout from '../components/layout'
import SEO from '../components/seo'

const Headline = styled.h1`
  margin-top: 24px;
  font-size: 4rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  line-height: 42px;
`

const Subline = styled.p`
  margin-top: 0.25rem;
  margin-bottom: 2.5rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  font-weight: 200;
  line-height: 32px;
`

const Para = styled.p`
  margin-top: 4rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  line-height: 32px;
`

const MediationNeuGedacht = () => (
  <Layout>
    <SEO title="Mediation neu gedacht" />
    <div className="grid">
      <div className="grid-contained">
        <Headline>Mediation neu gedacht in 2020.</Headline>
        <Subline>
          Konfliktrezept hilft dir und deinem Team Konflikte nachhaltig zu
          lösen!
        </Subline>

        <Para>
          Wie wir dazu kommen, zu sagen, dass wir Mediation “neu gedacht” haben?
        </Para>
        <Para>
          Mediation ist doch ein alter Hut und wer sich ein wenig mit
          Kommunikation auseinandersetzt, kommt auch schnell zur Mediation…
        </Para>
        <Para>
          Doch schon allein eine Google Suchanfrage zeigt mir, dass Mediation
          kein Thema ist, mit dem sich jeder sofort und gerne beschäftigt.
        </Para>
        <Para>
          Oft spielen <b>Gefühle</b> eine große Rolle. Sei es die Angst vor
          Veränderung, vor Konflikten, vor Tipps von außen und vielleicht auch
          manchmal Scham zuzugeben, dass ich selbst die Situation nicht mehr
          unter Kontrolle habe.
        </Para>
        <Para>
          Wir wollen diese Gefühle auflösen bzw. gemeinsam an ihnen arbeiten,
          dass jede*r davon profitiert.
        </Para>
        <Para>
          Frei nach dem Spruch „Verbunden werden auch die Schwachen mächtig.“
          von Friedrich von Schiller aus Wilhelm Tell.
        </Para>
        <Para>
          Wir wollen Mitarbeiter*innen, Kolleginnen und Kollegen und
          Führungskräfte dazu animieren sich gegenseitig in ihren Sichtweisen
          ernst zu nehmen und zu unterstützen.
        </Para>
        <Para>
          Gerade in dieser <b>Krisensituation</b>, in der wir leben, ist es
          wichtig, aufeinander zu achten und miteinander zu kommunizieren, was
          einen bewegt.
        </Para>
        <Para>
          Bei einem ist es die <b>Überforderung</b>, Kinder zu betreuen und das
          Home Office und die Anforderungen der Arbeit unter einen Hut zu
          bekommen. Bei dem anderen ist es die <b>Einsamkeit</b> in der eigenen
          Wohnung, die <b>Isolation</b> von Freunden und Familie, weil sie nicht
          direkt nebenan wohnen oder zur <b>Risikogruppe</b> gehören.
        </Para>
        <Para>
          Welche Herausforderungen es auch immer sind, gestehen wir uns ein,
          dass sie unseren Alltag und auch unser Berufsleben stark beeinflussen
          und auch nicht wieder so einfach verschwinden.
        </Para>
        <Para>
          Wir werden uns langfristig mit Themen, die uns ins dieser Krise
          beschäftigen auseinandersetzen müssen. Und das soll ganz und gar nicht
          einen pessimistischen Blick auf die Zukunft werfen, sondern vielmehr
          zum <b>Denken anstoßen</b>, wie jeder Einzelne von uns dies machen
          möchte.
        </Para>
        <Para>
          Für mich ist eine <b>mediative Haltung</b> eine Methode diesem ‘neuen’
          Alltag zu begegnen. In meiner Ausbildung 2018 habe ich gelernt sie in
          meinen privaten wie auch Arbeitsalltag zu integrieren, eine Methode
          mit Gelassenheit und <b>Vertrauen zu den Menschen</b> und in unsere
          Gesellschaft Herausforderungen und auch Konflikten zu begegnen.
        </Para>
        <Para>
          Franziska und ich haben uns entschieden in dieser krisenhaften Zeit
          einen <b>Schritt nach vorne</b> zu treten und zwar uns selbstständig
          zu machen mit unserer Zusatzqualifikation der <b>Mediation</b> — wir
          als <b>Konfliktrezept</b> unterstützen Menschen, Teams und
          Unternehmen.
        </Para>
        <Para>
          In unserem Blog wollen wir ganz alltägliche Einblicke und Beispiele
          aus unserem Berufsalltag geben.
        </Para>
        <Para>
          Wie können Konflikte oder auch „nur“ kommunikative Störungen im
          beruflichen Kontext mit Rezepten der Mediation und Kommunikation
          aufgelöst und vermittelt werden?
        </Para>
        <Para>
          Was kann jeder selbst tun und lernen, um eine Kommunikation auf
          Augenhöhe im Büro und Home Office zu gewährleisten?
        </Para>
        <Para>
          Wie wollen wir miteinander umgehen und die Krise als Antreiber sehen,{' '}
          <b>Innovationen zu denken</b> und <b>digitale Lösungen</b> noch mehr
          zu integrieren?
        </Para>
        <Para>
          Mich hat das Buch <b>„Das neue Land — Wie es jetzt weitergeht!“</b>{' '}
          von Verena Pausder noch einmal mehr davon überzeugt, dass wir{' '}
          <b>Alle</b> mit verantwortlich sind und uns mit daran beteiligen
          können, unsere Gesellschaft und unsere <b>Welt zu wandeln</b>.
        </Para>
        <Para>
          Wir wollen hier die Möglichkeit zum <b>offenen Austausch</b> über
          Konflikte und Konfliktfolgen sowie präventive Kommunikations-Methoden
          und Mechanismen geben, das ist unser Beitrag,{' '}
          <b>Wandel herbeizuführen</b> im Umgang der Menschen untereinander.
        </Para>
        <Para>
          Wir freuen uns auf einen gemeinsamen Weg mit euch durch tägliche
          Herausforderungen, Kommunikations-Irrtümer und Konfliktsituationen.
        </Para>
        <Para>
          Fangen wir an <b>Konflikte als Auslöser von etwas Gutem</b> zu sehen,
          schaffen wir <b>Entwicklung</b> und <b>Zukunftsperspektive</b>!
        </Para>
        <Para>
          Mediation neu gedacht! Das sind wir! Das ist Konfliktrezept!
        </Para>
      </div>
    </div>
    <FooterIndex />
  </Layout>
)

export default MediationNeuGedacht

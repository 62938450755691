import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Header from './header'
import GlobalStyle from '../styles/global'

const Layout = ({ children, isIndexPage = false }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <GlobalStyle />
      <Header
        siteTitle={data.site.siteMetadata.title}
        isIndexPage={isIndexPage}
      />
      <main>{children}</main>
    </>
  )
}

export default Layout

import React from 'react'
import { Link } from 'gatsby'
import { css } from 'styled-components'
import { device } from '../styles/devices'

const FooterIndex = ({ isSinglePage = false }) => (
  <div
    css={`
      /* background: linear-gradient(155deg, #3668ed 17%, #6d95ff 92%); */
      ${!isSinglePage &&
        `
        margin-top: 100px;
        padding-top: 75px;
        background: #3668ed;
      `};
    `}
  >
    <div className="grid">
      <div className="grid-contained">
        <div
          css={`
            /* display: flex; */
          `}
        >
          <div
            css={css`
              ${!isSinglePage &&
                css`
                  margin-bottom: 75px;

                  @media ${device.tablet} {
                    margin: 0 auto;
                    width: 700px;
                  }

                  @media ${device.laptop} {
                    margin: 0 auto;
                    width: 950px;
                  }
                `}

              & > h2 {
                margin-bottom: 20px;
                font-size: 3.2rem;
                ${!isSinglePage && `color: #fff;`}
              }

              & > p {
                margin-bottom: 20px;
                opacity: 0.95;
                font-size: 1.8rem;
                line-height: 33.18px;
                letter-spacing: -0.004em;
                ${!isSinglePage &&
                  `
                  color: #fff;
                  font-weight: 300;
                `}
              }
            `}
          >
            <h2>Kontakt</h2>
            <p>
              Kontaktieren Sie uns für ein unverbindliches und kostenfreies
              Vorgespräch!
            </p>
            <p>
              Wir entwerfen gerne anhand Ihrer Vorstellungen und unserer
              Kompetenzen ein maßgeschneidertes Angebot für Sie!
            </p>
            <p>
              <span>
                Sie erreichen uns über die E-Mail-Adresse{' '}
                <a
                  href="mailto:hallo@konfliktrezept.de"
                  css={css`
                    border-bottom: 2px solid #facd3d;
                  `}
                >
                  hallo@konfliktrezept.de
                </a>
                .
              </span>
            </p>
            {/* <p>
              Sie erreichen uns über das Kontaktformular und per Telefon unter
              0174/5427422.
            </p> */}
          </div>
          {/* <div
            css={css`
              margin: -16px;
              background-color: white;
              border-radius: 24px;
              ${!isSinglePage &&
                `
                box-shadow: 0 8px 10px 5px rgba(0, 0, 0, 0.1);
              `}

              @media ${device.tablet} {
                margin: 0;
              }
              
              @media ${device.laptop} {
                margin: 0 auto;
                width: 600px;
              }
            `}
          >
            <form
              css={css`
                padding: 28px 16px;

                ${isSinglePage &&
                  `
                  @media ${device.tablet} {
                    margin: 0 auto;
                    width: 500px;
                  }
                `}

                & > div:not(:last-child) {
                  margin-bottom: 25px;
                }

                & label {
                  display: block;
                  margin-bottom: 12px;
                  color: #505050;
                }

                & input[type='text'],
                & input[type='email'],
                & textarea {
                  padding: 0 20px;
                  width: 100%;
                  border: 2px solid #f3f3f3;
                  border-radius: 20px;
                  background-color: #f3f3f3;
                  background-clip: padding-box;

                  &:focus {
                    outline: none;
                    border-color: #3668ed;
                  }
                }

                & input {
                  height: 42px;
                }

                & textarea {
                  padding: 20px;
                  resize: none;
                  line-height: 1.5em;
                }

                @media ${device.tablet} {
                  padding: 38px 28px;
                }
              `}
            >
              <div>
                <label htmlFor="name">Name</label>
                <input id="name" type="text" name="textfield" />
              </div>

              <div>
                <label htmlFor="firma">Firma</label>
                <input id="firma" type="text" name="firma" />
              </div>

              <div>
                <label htmlFor="email">E-Mail</label>
                <input id="email" type="email" name="email" />
              </div>

              <div>
                <label htmlFor="message">Nachricht</label>
                <textarea id="message" name="message" rows="6" />
              </div>

              <div
                css={css`
                  & > input {
                    margin-right: 16px;
                    height: unset;
                  }

                  & > label {
                    display: inline;
                  }
                `}
              >
                <input id="dsgvo" name="dsgvo" type="checkbox" />
                <label htmlFor="dsgvo">Keine Ahnung</label>
              </div>

              <div
                css={css`
                  text-align: right;
                `}
              >
                <button
                  css={css`
                    padding: 12px 32px;
                    color: #61510c;
                    font-size: 2rem;
                    background-image: radial-gradient(
                      ellipse at top,
                      #f9dc5c 10%,
                      #facd3d 100%
                    );
                    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
                      0 4px 6px -2px rgba(0, 0, 0, 0.1),
                      inset 0 2px 1px 0 rgba(255, 255, 255, 0.2),
                      inset 0 -2px 1px 0 rgba(0, 0, 0, 0.1);
                    border-radius: 140px;

                    &:hover {
                      background-image: radial-gradient(
                        ellipse at top,
                        #fbe378 10%,
                        #f9dc5c 100%
                      );
                    }
                  `}
                >
                  Senden
                </button>
              </div>
            </form>
          </div> */}
        </div>
      </div>
      <div
        className="grid-contained"
        css={css`
          margin-top: 100px;
          margin-bottom: 100px;
          text-align: center;

          & > a {
            display: block;
            margin-bottom: 12px;
            color: #d0daf7;
            ${isSinglePage && `color: #3668ed;`}
          }

          @media ${device.tablet} {
            display: flex;
            justify-content: space-evenly;
          }
        `}
      >
        {/* <Link to="/">XING</Link> */}
        {/* <Link to="/">LinkedIn</Link> */}
        <a href="https://instagram.com/konfliktrezept" target="_blank">
          Instagram
        </a>
        <a href="https://twitter.com/konfliktrezept" target="_blank">
          Twitter
        </a>
        <Link to="/datenschutz">Datenschutz</Link>
        <Link to="/impressum">Impressum</Link>
      </div>
    </div>
  </div>
)

export default FooterIndex

import { Link } from 'gatsby'
import React from 'react'
import { css } from 'styled-components'

import Navigation from './navigation'
import logo from '../images/logo.svg'
import logoWhite from '../images/logo-white.svg'
import { device } from '../styles/devices'

const Header = ({ siteTitle, isIndexPage = false }) => (
  <header
    className="grid"
    css={css`
      height: 10rem;
      ${isIndexPage ? 'background: transparent' : 'background: #fff'};
      ${isIndexPage && 'position: absolute; z-index: 2; left: 0; right: 0;'};

      @media (min-width: 1024px) {
        height: 12rem;
      }
    `}
  >
    <div
      className="grid-contained"
      css={css`
        display: flex;
        justify-content: space-between;
      `}
    >
      <h1>
        <Link to="/">
          <img
            src={isIndexPage ? logoWhite : logo}
            alt={siteTitle}
            css={css`
              height: 30px;
              margin-top: 35px;

              @media ${device.mobileM} {
                height: 40px;
                margin-top: 30px;
              }

              @media ${device.laptop} {
                height: 45px;
                margin-top: 38px;
              }
            `}
          />
        </Link>
      </h1>
      <Navigation isIndexPage={isIndexPage} />
    </div>
  </header>
)

export default Header

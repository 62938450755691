import { Link } from 'gatsby'
import React, { useState } from 'react'
import { css } from 'styled-components'
import { useMediaQuery } from 'react-responsive'

import { grow, fadeIn } from '../styles/animations'

const links = [
  { title: 'Mediation', to: '/mediation' },
  { title: 'Workshops', to: '/workshops' },
  // { title: 'Moderation', to: '/moderation' },
  { title: 'Team', to: '/team' },
  { title: 'Kontakt', to: '/kontakt' },
].map(p => (
  <Link
    to={p.to}
    key={p.to}
    activeClassName="active"
    css={css`
      margin: 0.6rem 4.6rem;
      padding: 0.6rem 2rem;
      color: #08123e;
      font-size: 2.8rem;
      border-radius: 1000px;

      &.active {
        color: #fff;
        background-color: #3668ed;
      }

      @media (min-width: 1024px) {
        margin: 0 0.8rem;
        padding: 0;
        color: #222;
        font-size: 1.7rem;

        &:hover {
          color: #3668ed;
        }

        &.active {
          background-color: transparent;
          color: #3668ed;
          border-radius: 0;
          border-bottom: 2px solid;
          cursor: default;
        }
      }
    `}
  >
    {p.title}
  </Link>
))

const NavigationMobile = () => {
  const [isToggledOn, setToggle] = useState(false)
  const toggle = () => setToggle(!isToggledOn)
  return (
    <>
      <button
        onClick={toggle}
        aria-label={`${isToggledOn ? 'close menu' : 'open menu'}`}
        css={css`
          position: relative;
          width: 26px;
          height: 26px;
          z-index: 100;
          top: 3.3rem;
          :hover:not(.touch),
          :focus {
            border: none;
            outline: none;
          }
        `}
      >
        <div
          css={css`
            position: absolute;
            left: 0;
            width: 26px;
            height: 5px;
            border-radius: 4px;
            ${isToggledOn ? 'background: transparent' : 'background: #9bb6ff'};
            transition: all 200ms ease-out;
            ::before {
              position: absolute;
              top: -8px;
              right: 0;
              height: 5px;
              content: '';
              border-radius: 4px;
              ${isToggledOn ? 'background: #3668ed' : 'background: #9bb6ff'};
              ${isToggledOn ? 'width: 26px' : 'width: 18px'};
              ${isToggledOn
                ? 'transform: rotate(45deg); top: 0; '
                : 'transform: rotate(0)'};
              transition: all 200ms ease-out;
            }
            ::after {
              position: absolute;
              top: 8px;
              right: 0;
              height: 5px;
              content: '';
              border-radius: 4px;
              ${isToggledOn ? 'width: 26px' : 'width: 22px'};
              ${isToggledOn ? 'background: #3668ed' : 'background: #9bb6ff'};
              ${isToggledOn
                ? 'transform: rotate(-45deg); top: 0;'
                : 'transform: rotate(0)'};
              transition: all 200ms ease-out;
            }
          `}
        />
      </button>

      {isToggledOn && (
        <div
          css={css`
            position: absolute;
            z-index: 90;
            top: 30px;
            right: 10px;
            min-width: 35vh;
            background-color: #fff;
            border: 1px solid #f6f6f6;
            border-radius: 8px;
            box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
              0 4px 6px -2px rgba(0, 0, 0, 0.05);
            transform-origin: 92% 5%;
            animation: ${grow} 200ms ease-out, ${fadeIn} 200ms ease-out;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              height: 375px;
              text-align: center;
              overflow-y: auto;
            `}
          >
            {links}
          </div>
        </div>
      )}
    </>
  )
}

const NavigationDesktop = ({ isIndexPage }) => (
  <nav
    css={css`
      line-height: 12rem;

      ${isIndexPage &&
        css`
          & a {
            color: #fff;

            &.active {
              color: #fff;
              border-color: #fff;
            }

            &:hover {
              color: #fff;
              border-radius: 0;
              border-bottom: 2px solid;
            }
          }
        `}
    `}
  >
    {links}
  </nav>
)

const Navigation = ({ isIndexPage }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 1023px)' })
  return isMobile ? (
    <NavigationMobile />
  ) : (
    <NavigationDesktop isIndexPage={isIndexPage} />
  )
}
export default Navigation

import { createGlobalStyle } from 'styled-components'
import { device } from './devices'

const GlobalStyle = createGlobalStyle`
  // Reset
  *,
  *::before,
  *::after {box-sizing:border-box;}
  a {text-decoration:none; color:inherit; cursor:pointer;}
  button {background-color:transparent; color:inherit; border-width:0; padding:0; cursor:pointer;}
  figure {margin:0;}
  input::-moz-focus-inner {border:0; padding:0; margin:0;}
  ul, ol, dd {margin:0; padding:0; list-style:none;}
  h1, h2, h3, h4, h5, h6 {margin:0; font-size:inherit; font-weight:inherit;}
  p {margin:0;}
  cite {font-style:normal;}
  fieldset {border-width:0; padding:0; margin:0;}
  input:-webkit-autofill {-webkit-box-shadow:0 0 0 500px white inset;}
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {-webkit-appearance: none; margin: 0;}

  // Typography
  html { 
    font-size: 62.5%;
  }

  body {
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.3;
    color: #222;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Baloo', cursive;
    color: #08123e;
  }

  // Grid
  .grid {
    display: grid;
    grid-template-columns: 
        [viewport-start] minmax(1.6rem, 1fr)
        [container-start] minmax(0, 1100px) [container-end]
        minmax(1.6rem, 1fr) [viewport-end];
  }

  .grid-contained {
      grid-column: container;
  }

  .grid-full { 
      grid-column: viewport; 
      display: inherit;
      /*grid-template-columns: inherit;*/
  }

/*  .grid-full > * {
      grid-column: container;
  }*/

  // Global defaults

`

export default GlobalStyle
